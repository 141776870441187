import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import * as Icons from '../../icons';
import classNames from 'classnames/bind';
import style from './index.module.css';
import { Table } from '../../components/DataTable';
import { LEADERBOARD_COLUMN_SIZE } from '../../constants';
import { Column } from 'react-table';
import { LeaderboarType } from '../../model/index';
import { useAsync } from 'react-use';
import api from '../../api';

const cx = classNames.bind(style);

function useLeaderboard(cu_id: string) {
  const { loading, value } = useAsync(
    () => api.getLeaderBoard({ cu_id }),
    [cu_id]
  );
  return {
    loading,
    value,
    code: value?.code,
    error: value?.err,
  };
}

interface CampaignRankModelProps {
  isOpen: boolean;
  handleClose: () => void;
  cuid: string;
  loginInfo: any;
  game: any;
  handleGameCampaign: (
    cuid: string,
    campaignName: string,
    code: string,
    userName: string,
    m_type: string
  ) => void;
}

interface CampaignItem {
  code: string;
  name: string;
  regstatus: boolean;
  signed: boolean;
  user_id: string;
  camp_type: number; // 0: 個人 1:團體
  reg_start: string;
  reg_end: string;
  act_type: 'S' | 'C' | 'N' | null; // S: 校園 C: 企業 N: 個人
  d_end: string;
}

// 添加 Base64 解碼函數
function decodeBase64(base64: string): string {
  try {
    const binaryString = atob(base64);
    const utf8Decoder = new TextDecoder('utf-8');
    const binaryArray = Uint8Array.from(binaryString, (c) => c.charCodeAt(0));
    return utf8Decoder.decode(binaryArray);
  } catch (e) {
    console.error('Failed to decode Base64 string:', e);
    return '';
  }
}

const CampaignRankModel: React.FC<CampaignRankModelProps> = ({
  isOpen,
  handleClose,
  cuid,
  loginInfo,
  game,
  handleGameCampaign,
}) => {
  const [openSingleItems, setOpenSingleItems] = useState<{ [key: string]: boolean }>({});
  const [filteredGames, setFilteredGames] = useState<CampaignItem[]>([]);
  const [decodedCode, setDecodedCode] = useState<string | null>(null);
  const { loading: LeaderboardLoading, value } = useLeaderboard(cuid);
  const [userName, setUserName] = useState('');

  // 從 localStorage 中解碼並設置 decodedCode
  useEffect(() => {
    const localStorageCode = localStorage.getItem('code');
    if (localStorageCode) {
      try {
        const decoded = decodeBase64(localStorageCode);
        setDecodedCode(decoded);
      } catch (error) {
        console.error('Error decoding Base64:', error);
      }
    }
  }, []);
  // toggle 進去展開對應表單
  // useEffect(() => {
  //   if (initialCode && filteredGames.length > 0) {
  //     const matchedGame = filteredGames.find((game) => game.code === initialCode);
  //     if (matchedGame) {
  //       toggleSingleItem(matchedGame.code);
  //     }
  //   }
  // }, [initialCode, filteredGames]);

  // 競賽表單渲染
  // 從localStorage取出code，解密，並且篩選出未簽到或已簽到但未報名的資料
  // 過濾符合條件的遊戲
  useEffect(() => {
    if (decodedCode) {
      const newFilteredGames = game.filter((g: CampaignItem) => {
        const DistrationEnd = new Date(g.d_end);
        DistrationEnd.setHours(23, 59, 59, 999);
        const oneMonthAfterEnd = new Date(g.d_end);
        oneMonthAfterEnd.setMonth(oneMonthAfterEnd.getMonth() + 1);

        // 過濾條件：
        // 1. 必須是 'KGI' 開頭，或者與當前 URL 的解碼匹配的 code。
        // 2. 必須已報名 (signed 為 true)。
        // 3. 結束日期不能超過一個月。
        return (
          (g.code.startsWith('KGI') || g.code === decodedCode) &&
          g.signed === true &&
          new Date() <= oneMonthAfterEnd
        );
      });
      setFilteredGames(newFilteredGames);
    }
  }, [game, decodedCode]);


  // 展開或收合表單
  const toggleSingleItem = (code: string) => {
    setOpenSingleItems((prev) => ({
      ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
      [code]: !prev[code],
    }));
  };

  // 排行榜表格配置
  const leaderboard_columns = React.useMemo<Column<LeaderboarType>[]>(
    () => [
      { Header: '排名', accessor: 'ranking', disableSortBy: true },
      {
        Header: '排名變化',
        accessor: 'rank_prev',
        disableSortBy: true,
        Cell: ({ cell }) => (
          <div className={cx(cell.row.original.rank_prev >= 1 ? 'rank_up' : 'rank_down')}>
            {cell.row.original.rank_prev === 65535 ? '--' : cell.row.original.rank_prev}
          </div>
        ),
      },
      {
        Header: decodedCode?.startsWith('KGI') ? '玩家名稱' : '隊伍名稱',
        accessor: 'user_name',
        disableSortBy: true,
      },
      {
        Header: decodedCode?.startsWith('KGI') ? '獲利金額' : '投組淨值',
        accessor: 'nav',
        disableSortBy: true,
        Cell: ({ cell }) => (
          <div>{Math.round(+cell.row.original.nav).toLocaleString()}</div>
        ),
      },
      {
        Header: '報酬率',
        accessor: 'roi',
        disableSortBy: true,
        Cell: ({ cell }) => <div>{cell.row.original.roi.toFixed(2)}%</div>,
      },
    ],
    [decodedCode]
  );

  return (
    <Modal
      closeTimeoutMS={0}
      isOpen={isOpen}
      onRequestClose={handleClose}
      className=""
      overlayClassName="CampaignSubmitModelOverlay"
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      <div className={cx('d-flex', 'flex-column', 'flex-overlay')}>
        <div className={cx('mainModalTitle')}>
          <div className={cx('modalTitle')}>模擬競賽排名</div>
          <div className={cx('modalLeft')} onClick={handleClose}>
            <Icons.ArrowLeft fill={''} width={30} height={30} />
          </div>
        </div>

        {filteredGames.map((item: CampaignItem) => {
          const DistrationEnd = new Date(item.d_end);
          DistrationEnd.setHours(23, 59, 59, 999);
          const oneMonthAfterEnd = new Date(item.d_end);
          oneMonthAfterEnd.setMonth(oneMonthAfterEnd.getMonth() + 1);
          
          return (
            <div key={item.code} className={cx('px-2', 'submitContent')}>
              {new Date() <= oneMonthAfterEnd ? (
                <div>
                  <div
                    className={cx(
                      'd-flex',
                      'justify-content-between',
                      'section1Bg',
                      'align-items-center'
                    )}
                    onClick={() => {
                      toggleSingleItem(item.code);
                      api
                        .applyCampaign({
                          phone: loginInfo.phone,
                          email: loginInfo.email,
                          code: item.code,
                        })
                        .then((e) => {
                          if (e.err === '0000') {
                            const { name, code } = item;
                            const user_name = e.user_name || '';
                            setUserName(user_name);
                            // 如果 cu_id 是 null，將其設置為空字符串
                            handleGameCampaign?.(e.cu_id || '', name, code, user_name, '');
                          }
                        });
                    }}
                  >
                    <div className={cx('submitSection', 'ms-1')}>
                      <span>填寫報名資料: {`${item.name}`}</span>
                    </div>
                    <div className={cx('me-1', 'transition')}>
                      <Icons.ArrowRightNoHand fill={'#fff'} width={30} height={30} />
                    </div>
                  </div>

                  {openSingleItems[item.code] && (
                    <div className={cx('d-flex', 'flex-column', 'px-3')}>
                      <div className={cx('flex-grow-1', 'me-2')}>
                        <div className={cx('text-color', 'container-left', 'my-2')}>
                          排行榜
                          <span className={cx('badge-username')}>{userName}</span>
                          <span className={cx('ms-2')}>
                            排名 {value?.rank === 0 ? '--' : `#${value?.rank}`}
                          </span>
                        </div>
                        <Table
                          columns={leaderboard_columns}
                          data={value?.leaderboard || []}
                          loading={LeaderboardLoading}
                          pagination={true}
                          pageSize={LEADERBOARD_COLUMN_SIZE}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default CampaignRankModel;
