
//密碼長度應為6~12位數，包含英文及數字
// export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{12,30}$/;
export const REGEX_PASSWORD = /^[A-Za-z\d]{6,12}$/;
export const REGEX_DEPARTMENT = /^[\u4e00-\u9fa5]{1,15}$/; // 中文姓名1-15字
export const REGEX_NAME = /^[\u4e00-\u9fa5]{2,}$/; // 中文姓名2字以上
export const TAIWAN_ID_REGEX = /^[A-Z][1-2][0-9]{8}$/; // 身分證

//密碼長度應為6~12位數，包含英文及數字
export const REGEX_EMAIL =
/^(?!(gmail|hotmail).*\.tw$)(?!(.*\.con$))(?!(.*\.esu\.tw$))(?!(.*yahooo.*))[\w.-]+@[A-Za-z0-9-]+(\.[A-Za-z]{2,})?(\.[A-Za-z]{2,})?(\.[A-Za-z]{2,})?$/
export const REGEX_PHONE = /^[09]{2}\d{8}$/; // 手機號碼
export const REGEX_PROFESSOR = /^[\u4E00-\u9FFF\u3105-\u3129a-zA-Z]{0,10}$/; // 

// ---------------------------------------------------------------------------------------------------//
// 寶碩提供部分

//Google 部分 寶碩提供部分
//Google Client ID Required
// export const GOOGLE_CLIENT_ID = '956575344713-s312idakvjaagfikr5ao9kvsjlavij94.apps.googleusercontent.com'
// export const GOOGLE_SECRET = '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'

//Line 部分 寶碩提供部分
// export const LINE_LOGIN_CLIENT_ID = "2000060021"
// export const LINE_LOGIN_SECRET = '6ad264772b7ad87f17484d7e8a993669'
// export const LINE_LOGIN_URI = `${process.env.REACT_APP_URL}`
// export const LINE_LOGIN_URI = `https://kgistockuat.apex.game.tw/`

//Fb部分 寶碩提供部分
// export const FB_CLIENT_ID = '811778670624791'
// export const FB_SECRET = 'fa1514680fc90395958fa60eb31ad1b9'
// export const FB_LOGIN_URI = `${process.env.REACT_APP_URL}`

// ---------------------------------------------------------------------------------------------------//


// ---------------------------------------------------------------------------------------------------//
// 凱基提供部分


//  `${process.env.REACT_APP_TYPE}` 1:UAT 2:PROD

//Google UAT 部分 凱基提供部分
export const GOOGLE_CLIENT_ID = `${process.env.REACT_APP_TYPE}` === '1' ? '271961281616-meg6jvkai1t7jo6gf6v53842vicmcal7.apps.googleusercontent.com' : '271961281616-n86denccveu5564knfne2ue74fdb8jv8.apps.googleusercontent.com'
export const GOOGLE_SECRET = `${process.env.REACT_APP_TYPE}` === '1' ? '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' : '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'

// Google PROD 部分 凱基提供部分
// export const GOOGLE_CLIENT_ID = '271961281616-n86denccveu5564knfne2ue74fdb8jv8.apps.googleusercontent.com'
// export const GOOGLE_SECRET = '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'




//Line  UAT 部分  凱基提供部分
export const LINE_LOGIN_CLIENT_ID = `${process.env.REACT_APP_TYPE}` === '1' ? "2000287061" : "2001663150"
export const LINE_LOGIN_SECRET = `${process.env.REACT_APP_TYPE}` === '1' ? "ec79fb6de297bbfe3bd9949c145679fb" : "84a9370cd481e7686336c9efb78afbeb"
export const LINE_LOGIN_URI = `${process.env.REACT_APP_URL}`

//Line PROD 部分 凱基提供部分
// export const LINE_LOGIN_CLIENT_ID = "2001663150"
// export const LINE_LOGIN_SECRET = "84a9370cd481e7686336c9efb78afbeb"
// export const LINE_LOGIN_URI = `${process.env.REACT_APP_URL}`




//Fb部分 凱基提供部分
export const FB_CLIENT_ID = '247203898120182'
export const FB_SECRET = '10473f702dae110b724ee41136b745ea'
export const FB_LOGIN_URI = `${process.env.REACT_APP_URL}`
// ---------------------------------------------------------------------------------------------------//

export const LEADERBOARD_COLUMN_SIZE = 10
