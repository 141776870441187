import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import * as Icons from '../../icons';
import classNames from 'classnames/bind';
import style from './index.module.css';
import api from '../../api';
import { CampaignType } from '../../api/newApi/getCampaignQuery';

const cx = classNames.bind(style);
interface CampaignQAProps {
  isOpen: boolean;
  handleClose: () => void;
  type: 1 | 2 | 3; 
  campaigns: CampaignType[];
  loginInfo: { phone: string; email: string; uid: number };
}

const decodeCode = (encodedCode: string): string => {
  return atob(encodedCode);
};

const CampaignQAModel: React.FC<CampaignQAProps> = ({
  isOpen,
  handleClose,
  type,
  campaigns,
  loginInfo,
}) => {
  const [campaignsState, setCampaigns] = useState<CampaignType[]>(campaigns || []);
  const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({});
  // 只處理表單的收合，不清空任何資料

  const closeAllItems = () => {
    setOpenItems({});
  };

  useEffect(() => {
    setCampaigns(campaigns);
  }, [campaigns]);


  const toggleItem = async (code: string) => {
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [code]: !prevOpenItems[code],
    }));

    try {
      const e = await api.getCampaignQuery({
        phone: loginInfo.phone,
        email: loginInfo.email,
        uid: loginInfo.uid,
      });

      if (e && e.err === '0000' && e.campaign) {
        const updatedNote = e.campaign.find((c: CampaignType) => c.code === code)?.note || '';
        setCampaigns((prevCampaigns) =>
          prevCampaigns.map((c) => (c.code === code ? { ...c, note: updatedNote } : c))
        );
      } else {
        console.error('Error', e?.err || 'Unknown error');
      }
    } catch (error) {
      console.error('failed:', error);
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const encodedCode = urlParams.get('code');
  const decodedCode = encodedCode ? decodeCode(encodedCode) : '';

  return (
    <Modal
      isOpen={isOpen}
      contentLabel=""
      onRequestClose={() => {
        handleClose();
        closeAllItems(); // 只收合表單，保留資料
      }}
      className=""
      overlayClassName="CampaignSubmitModelOverlayWhite"
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      <div className={cx('d-flex', 'flex-column', 'flex-overlay')}>
        <div className={cx('mainModalTitle')}>
          <div className={cx('modalTitle')}>
            {type === 1 ? '活動辦法' : type === 2 ? '常見問題' : '公告'}
          </div>
          <div
            className={cx('modalLeft')}
            onClick={() => {
              handleClose();
              closeAllItems(); // 關閉時收合所有表單
            }}
          >
            <Icons.ArrowLeft fill={''} width={30} height={30} />
          </div>
        </div>

        {type === 1 ? (
          <div className={cx('submitContent')}>
            {campaignsState
              .filter((campaign) => {
                const currentDate = new Date();
                const endDate = campaign.d_end ? new Date(campaign.d_end) : null;
                // 如果 d_end 沒有值，則跳過該競賽
                if (!endDate || isNaN(endDate.getTime())) {
                  return false;
                }
                // 當前時間與結束時間
                const daysSinceEnd = Math.floor((currentDate.getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24));

                // 如果超過競賽結束後 30 天，不顯示該競賽
                if (daysSinceEnd > 30) {
                  return false;
                }
                return (campaign.signed && (campaign.code.startsWith('KGI') || campaign.code === decodedCode));
              })
              .map((campaign) => (
                <div key={campaign.code} className={cx('submitContent')}>
                  <div
                    className={cx(
                      'd-flex',
                      'justify-content-between',
                      'section1Bg',
                      'align-items-center'
                    )}
                    onClick={() => toggleItem(campaign.code)}
                  >
                    <div className={cx('submitSection', 'ms-1')}>
                      <span>{`${campaign.name} 活動辦法`}</span>
                    </div>
                    <div className={cx('me-1', 'transition')}>
                      <Icons.ArrowRightNoHand fill={'#fff'} width={30} height={30} />
                    </div>
                  </div>
                  {openItems[campaign.code] && (
                    <div className={cx('d-flex', 'flex-column', 'px-3', 'mt-3')}>
                      <div className={cx('flex-grow-1', 'me-2')}>
                        {campaign.note && campaign.note.trim().length > 0 ? (
                          campaign.note.split('\r\n').map((line, index) => (
                            <p key={index}>{line}</p>
                          ))
                        ) : (
                          <p>{`加載中....請重新嘗試點擊`}</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        ) : type === 2 ? (
          <div className={cx('px-4', 'pt-2', 'submitContent')}>
            <p>1. 請問參賽需要費用嗎?</p>
            <p>
              凱基證券與中華郵政舉辦之模擬交易競賽，均為免費提供之服務，目的為推廣金融教育，鼓勵投資人透過模擬交易增進股票交易知識並認識風險。
            </p>
            <p>2. 請問模擬交易競賽是否有提供操作資金?</p>
            <p>
              如在本次模擬交易競賽報名截止日前具備凱基證券客戶身分，即享初始模擬金加碼，競賽模擬金為1,000萬元；非凱基證券客戶初始模擬金為100萬元。
            </p>
            <p>3. 模擬交易時庫存有金額，為何無法買入股票?</p>
            <p>
              當日下單金額不得超過虛擬現金資產總額(含尚未成交的在途下單)，例如庫存仍有400萬元，該次下單350萬元，若平台回饋“餘額不足”，請檢視是否有尚未成交的在途下單。
            </p>
            <p>4. 請問可當沖交易嗎?</p>
            <p>
              系統預設開放現股當沖交易資格，當日買進且當天收盤前賣掉，視為現股當沖；若現股先賣出須按［現先賣］且收盤前須買進沖掉。若當日買進未於收盤前賣掉，系統將逕行沖銷，使用該項功能請務必自行評估是否影響個人操作績效。系統不提供融資/融券之信用當沖交易。
            </p>
            <p>5. 競賽參加獎是每個人都可以獲得嗎?</p>
            <p>
              只要於活動期間新開立凱基證券台股帳戶及分戶帳(e財庫)之新客戶，下載隨身e策略APP，選擇模擬交易，註冊後報名參賽並完成5筆模擬交易，不論獲利多寡，均可獲得300元獎金(宜睿智慧電子禮券)，將於2024.11.30前發送兌換連結及序號至得獎者於註冊模擬交易時提供之email。
            </p>
            <p>6. 請問參加獎中的全新開立本公司台股帳戶的定義是什麼?</p>
            <p>
              新戶定義為本次活動新開立本公司台股帳戶前，未曾於本公司開立過任何台股帳戶。
            </p>
            <p>7. 競賽分享獎如何可以獲得?</p>
            <p>
              競賽分享獎，只要於競賽期間回饋50字以上的參賽心得，舉凡選股過程、操作流程、投資風險體驗等，email至主辧單位 <a href="mailto:bmk@kgi.com">bmk@kgi.com</a>，活動結束一周後將由主辧單位擇優挑選20位，每位獲贈1,000點「i郵購紅利購物金點數」。
            </p>
            <p>8. 我符合得獎資格，請問如何通知我?</p>
            <p>
              主辦單位將以註冊模擬交易時所提供的email聯繫得獎人，註冊時請留意所提供的email是否填寫正確，是否為經常使用之電子信箱。
            </p>
            <p>9. 如何領取競賽獎或分享獎之i郵購紅利購物金點數?</p>
            <p>
              (一)將於2024.11.30前，依得獎者於活動報名時提供之身分證字號，以行動郵局APP推播發送紅利點數序號至行動郵局APP通知收件夾-優惠訊息區內。
            </p>
            <p>
              (二)得獎者須於得獎名單公布後，申請網路郵局使用者代號、網路密碼及下載、登入行動郵局APP，並開啟優惠訊息推播通知(無開啟行動郵局APP推播通知者，恕無法領取競賽獎或分享獎之贈獎)。
            </p>
            <p>10. 哪裡可以使用i郵購紅利購物金點數?有使用期限嗎?</p>
            <p>
              請註冊並登入i郵購會員<a href='https://www.postmall.com.tw/index.aspx'>https://www.postmall.com.tw/index.aspx</a> 點選「會員中心」&gt;點選「登錄紅利點數」&gt;點選「紅利兌領」，輸入「i郵購紅利購物金序號」、「驗證碼」後點選「登錄紅利」，即可於i郵購消費時使用。本次活動贈送之購物金自行動郵局APP推播發送購物金之日起使用效期為1年。
            </p>
          </div>
        ) : type === 3 ? (
          <div className={cx('px-4', 'pt-2', 'submitContent')}>
            <p>1.test</p>
            <p>2.test</p>
            <p>3.test</p>
            <p>4.test</p>
            <p>5.test</p>
          </div>
        )
          : null}
      </div>
    </Modal>
  );
};

export default CampaignQAModel;
