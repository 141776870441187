import { slide as Menu } from 'react-burger-menu';
import React from 'react';
import style from './index.module.css';
import classNames from 'classnames/bind';
import './burgerStyle.css';
import { useState } from 'react';
const cx = classNames.bind(style);



interface BurgerProps {
  setCampaignShow: (e: boolean) => void;
  setCampaignQAType: (e: 1 | 2 | 3) => void;
  setCampaignQAShow: (e: boolean) => void;
  setPasswordChangeType: (type: string) => void;
  openModel: () => void;
  campaignActive: boolean;
  regstatus: boolean;
  signed: boolean;
  campaignStartDate: string;
  campaignEndDate: string;
  setCampaignRankModel: (e: boolean) => void;  
}

const Burger: React.FC<BurgerProps> = ({
  setCampaignShow, // 顯示活動報名/查詢
  setCampaignQAType,
  setCampaignQAShow,
  setPasswordChangeType,
  openModel,
  campaignActive,
  regstatus,
  signed,
  campaignStartDate,
  campaignEndDate,
  setCampaignRankModel,
}) => {
  const [menuOpenState, setMenuOpenState] = React.useState(false);
  const [loginByThirdParty, setLoginByThirdParty] = React.useState(() => {
    return (
      window.sessionStorage.getItem('LOGIN_TYPE') &&
      window.sessionStorage.getItem('LOGIN_TYPE') === 'email'
    );
  });
  const handleCloseMenu = () => {
    setMenuOpenState(false);
  };
  const handleStateChange = (state: any) => {
    setMenuOpenState(state.isOpen);
  };

  const now = new Date();
  const startDate = new Date(campaignStartDate);
  const endDate = new Date(campaignEndDate);
  const isCampaignPeriod = now >= startDate && now <= endDate;
  
  // const hideCampaignOptions = regstatus === false && signed === false;
  // const showCampaignOptions = (campaignActive || regstatus === false || signed === true || isCampaignPeriod) && !hideCampaignOptions;
  const handleCampaignRankClick = () => {
    setCampaignRankModel(true);
    handleCloseMenu();
  };

  return (
    <Menu
      className={cx('burgerSetting')}
      isOpen={menuOpenState}
      onStateChange={handleStateChange}
    >
      <div
        className="menu-item"
        style={{ backgroundColor: '#003680', minHeight: '55px' }}
      ></div>
      {campaignActive && (
        <div
          id="about"
          className="menu-item"
          onClick={() => {
            setCampaignShow(true);
            handleCloseMenu();
          }}
        >
          <span>活動報名/查詢</span>
        </div>
      )}

      {campaignActive && (
        <div
          className="menu-item--small"
          onClick={() => {
            setCampaignQAType(1);
            setCampaignQAShow(true);
            handleCloseMenu();
          }}
        >
          <span>活動辦法</span>
        </div>
      )}
      {campaignActive && (
        <div
          className="menu-item--small"
          onClick={() => {
            setCampaignQAType(2);
            setCampaignQAShow(true);
            handleCloseMenu();
            
          }}
        >
          <span>常見問題</span>
        </div>
      )}
      <div
        className={cx('menu-item--small', !loginByThirdParty && 'd-none')}
        onClick={() => {
          handleCloseMenu();
          setPasswordChangeType('change');
          openModel();
        }}
      >
        <span>變更密碼</span>
      </div>
      {campaignActive && (
        <div className="menu-item--small"
          onClick={() => {
            // window.open('https://event.kgi.com.tw/news/event/STG/index.html');
          }}>
          <span>活動網頁</span>
        </div>
      )}
      <div
        className="menu-item--small"
        onClick={() => {
          window.location.href = 'https://www.youtube.com/@KGISIA.channel';
        }}
      >
        <span>凱基股股漲</span>
      </div>
      <div
        className="menu-item--small"
        onClick={() => {
          window.location.href = 'https://eoa.kgi.com.tw/OOA/index.aspx?Source=E240401';
        }}
      >
        <span>線上快速開戶</span>
      </div>
      <div
        className="menu-item--small"
        onClick={handleCampaignRankClick}
      >
        <span>競賽排名</span>
      </div>
      {/* {campaignActive && (
          <div
            className="menu-item--small"
            onClick={() => {
              setCampaignQAType(3);
              setCampaignQAShow(true);
              handleCloseMenu();
              
            }}
          >
            <span>系統公告</span>
          </div>
      )} */}
      <div className="ver">{process.env.REACT_APP_VER}</div>
    </Menu>
  );
};

export default Burger;
